export default {
    head: {
        title: '',
        meta: {
            description: '工程保函通是一站式银行保函办理平台，3分钟办理，24小时出函，全流程线上办理，价格透明，没有中间商赚差价。支持微信端，随时随地急速办理。',
            keywords: '保函通，保函，保函办理，保函在线办理，电子保函，工程保函，保证金，投标保证金，担保公司，银行保函，投标保函，履约保函，手机办理保函，保函查询，免交保证金',
        },
        icon: ''
    },
    plugins: {
        element: true,
        myComs: true,
        vuexStorage: false,
        jquery: true,
        sentry: false,
        cnzz: {
            id: '1281276883',
            show: 'pic1'
        }
    },
    proxy: {
        '/bhtong': {
            target: 'http://192.168.1.7:8180',
            // target: 'http://192.168.1.100:8011',
            // target: 'http://192.168.1.100:12020',
            // target: 'http://www.bhtong.cn',
            // target: 'http://bdmc.bhtong.cn',
            changeOrigin:true,
        },
        '/oss/bhtong': {
            target: 'https://oos-cn.ctyunapi.cn/bhtong/',
            changeOrigin:true,
        },
        /**
         * 下列配置仅在正式环境生效
         * 若不想使用默认的预渲染端口，则需在此进行配置追加
         */
        '/ssr-8012': {
            reg: /(cfd|tbbz\.xminsuber)/,
            target: 'http://127.0.0.1:8012',
            changeOrigin:true,
            pathRewrite: {
                '/ssr-8012': '',
            },
        },
        '/ssr-8013': {
            reg: /(bdmc|tbbz1\.xminsuber|8888)/,
            target: 'http://127.0.0.1:8013',
            changeOrigin:true,
            pathRewrite: {
                '/ssr-8013': '',
            },
        },
        '/ssr-8014': {
            reg: /(fuyang)/,
            target: 'http://127.0.0.1:8014',
            changeOrigin:true,
            pathRewrite: {
                '/ssr-8014': '',
            },
        },
        '/ssr-8015': {
            reg: /(cloud-sun)/,
            target: 'http://127.0.0.1:8015',
            changeOrigin:true,
            pathRewrite: {
                '/ssr-8015': '',
            },
        },
    },
}
